<template>
	<div class="serverlist  m-lg:px-4 px-4">
		<template v-if="servers && !error404">
			<table class="seo-serverlist">
				<thead>
				<tr>
					<th>Rank</th>
					<th>Players</th>
					<th>IP Address</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(server, index) in seoServers" :key="'hidden_server_' + index + '_' + server._id">
					<td>{{ index + 1 }}</td>
					<td>Players: {{ server.status.players.online }}/{{ server.status.players.max }}</td>
					<td> {{ server.ip }}</td>
				</tr>
				</tbody>
			</table>

			<div class="mb-3 mt-8"
			     :class="{
							'flex': props.filter === 'user' || props.filter === 'favourite',
							'justify-between': props.filter === 'user' || props.filter === 'favourite'
						 }">
				<h1 v-if="typeof pageTitle === 'string'" class="text-3xl" v-html="pageTitle"/>
				<div v-else-if="typeof pageTitle === 'object'" class="flex flex-col gap-1">
					<h1 class="text-3xl" v-html="pageTitle[0]"/>
					<h2 class="text-lg font-normal" v-html="pageTitle[1]"/>
					<h3 v-if="pageTitle[2]" class="text-lg font-normal" v-html="pageTitle[2]"/>
				</div>
				<div v-if="props.filter === 'user' || props.filter === 'favourite'"
				     class="flex justify-end m-lg:hidden">
					<NuxtLink to="/server/add" class="flex gap-1 items-center text-white-0 bg-blue-700 rounded-lg px-3 py-2 text-sm
													  hover:bg-blue-800">
						Add New Server
					</NuxtLink>
				</div>
			</div>
			<div class="flex gap-3 m-lg:justify-around h-9"
			     v-if="props.filter !== 'user' && props.filter !== 'favourite'">

				<select v-model="sortFilter"
				        class="bg-gray-light rounded-xl px-2.5 py-1.5 w-30 border border-gray-400 m-lg:w-fit"
				        label="Sort by"
				        aria-label="sortFilter">
					<option value="ranked">Top Ranked</option>
					<option value="players">Top Players</option>
					<option value="recent">Most Recent</option>
				</select>
				<select v-model="platformFilter"
				        class="bg-gray-light rounded-xl px-2.5 py-1.5 w-30 border border-gray-400 m-lg:w-fit"
				        label="Platform"
				        id="platformFilter"
				        aria-label="platformFilter">
					<option value="all">All servers</option>
					<option value="java">Java</option>
					<option value="bedrock">Bedrock</option>
				</select>
				<select v-if="sortFilter === 'votes'" v-model="timeFilter"
				        class="bg-gray-light rounded-xl px-2.5 py-1.5 w-30 border border-gray-400 m-lg:w-fit"
				        label="Time"
				        id="timeFilter"
				        aria-label="timeFilter">
					<option value="this-year">This year</option>
					<option value="this-month">This month</option>
					<option value="this-week">This week</option>
				</select>
			</div>
			<div class="flex justify-end m-lg:hidden"
			     v-if="props.filter !== 'user' && props.filter !== 'favourite'">
				<button class="flex gap-1 items-center bg-gray-400 rounded-lg px-2 py-0.5 cursor-pointer hover:bg-gray-600"
				        @click="addNewServer">
					<span>Add your server</span>
					<img src="/icons/right_arrow_small.svg" alt="Add Server Icon" class="w-3 h-4"
					     width="6" height="8"/>
				</button>
			</div>
			<template v-if="servers.length > 0">
				<div v-for="(server, index) in servers"
				     :key="`server_${index}`"
				     class="server-tile"
				     :style="{animationDelay: `${index * 0.1}s`}">
					<TilesServerListTile :server="server" :index="index" :current-page="currentPage"
					                     :featured-servers-length="featuredServersLength"
					                     :sponsor-info="sponsorInfo"
					                     :filter="filter"
					                     :key="`server_tile_${index}`"
					                     :fetch-id="fetchId"/>
				</div>
			</template>
			<div v-else class="h-20">
			</div>
			<IndexPaging v-if="maxPages > 1" :current-page="currentPage" :max-page="maxPages" :filter="filter"/>
		</template>
		<div v-else
		     class="flex flex-col gap-4 justify-center items-center mt-10 mb-2">
			<span class="text-2xl bg-white-0 bg-opacity-60 px-4 py-2 rounded-xl" v-html="noServersMessage"/>
			<NuxtLink v-if="totalServers > 0" :to="firstPageLink"
			          class="first-page  px-3 py-1 text-blue-500 border-2 border-blue-500 py-1.5 flex justify-center gap-2 items-center rounded-lg
							   hover:text-white-0 hover:bg-blue-500">
				<span>Go back to first page</span>
				<img alt="Arrow pointing to the right" src="/icons/right_arrow_small_white.svg"
				     width="10" height="10">
			</NuxtLink>
		</div>
	</div>
</template>

<script setup>
import { sanitizeBanner, titleEncode } from "~/utils/helpers.js";
import { notify } from '@kyvg/vue3-notification';

const gonePage = ref(false);

const route = useRoute();

const { setPopup } = usePopup();
const loggedInCookie = useCookie("loggedIn");

const params = route.query
if (params && (params.action || params.listingID)) {
	setResponseStatus(410);
	gonePage.value = true;

	watch(() => route.fullPath, () => {
		if (gonePage.value && route.fullPath === "/") {
			gonePage.value = false;
		}
	});
}

const props = defineProps({
	filter: {
		type: String,
		default: "default",
	},
	tag: {
		type: Object,
		default: null,
	},
	search: {
		type: String,
		default: null,
	},
});

let emptyPage = false;

const serversOnPage = 15;

const sortFilter = useSortFilter();
const platformFilter = usePlatformFilter();
const timeFilter = useTimeFilter();

const paramsPage = route.params.page
const currentPage = paramsPage ? Number(paramsPage) : 1

const servers = ref([]);
const seoServers = ref([]);

const totalServers = ref(0);
const featuredServersLength = ref(0);

const noServersMessage = ref('No servers found.');

const maxPages = ref(1);

const isAdminCookie = useCookie("is_admin");

const tagName = ref(null);

async function refreshIPCopyData() {
	if (!isAdminCookie.value) return;
	for (const server of servers.value) {
		try {
			server.ipCopyData = await $fetch(
				"/api/ipcopy/" +
				server.ip +
				(server.port && server.port != 25565 ? ":" + server.port : ""),
				{
					ignoreResponseError: true,
				}
			);
		} catch (e) {
			break;
		}
	}
}

const fetchId = ref(0);

async function refreshData() {
	fetchId.value = fetchId.value + 1;
	let query = {};

	if (props.filter === "default") {
		query = {
			filters: {
				type: "top",
			}
		};
	} else if (props.filter === "tag") {
		if (props.tag === null) {
			emptyPage = true
		} else {
			const filterTag = props.tag;
			delete filterTag.similar;
			query = {
				filters: {
					type: "tag",
					tag: {
						_id: filterTag._id,
						name: filterTag.name,
					}
				},
			};
		}

		noServersMessage.value = `No servers found for the tag <b>${encodeHTML(props.tag.name)}</b>.`;
	} else if (props.filter === "user") {
		query = {
			filters: {
				type: "user"
			},
			sorts: {
				creation_date: -1,
			},
		};

		noServersMessage.value = `You haven’t added any servers yet.`;
	} else if (props.filter === "favourite") {
		query = {
			filters: {
				type: "favourites"
			},
		};

		noServersMessage.value = `You haven’t marked any servers as favorites yet.`;
	} else if (props.filter === "search") {
		query = {
			filters: {
				type: "search",
				query: props.search.replace("<", "&lt;").replace(">", "&gt;").replace("&", "&amp;")
			},
			sorts: {
				[sortFilter.value]: -1,
			}
		};

		noServersMessage.value = `No servers matching the search term <b>${props.search.replaceAll("+", " ")}</b>.`;
	}

	query = {
		sorts: {
			...query.sorts,
			[sortFilter.value]: -1,
		},
		filters: {
			...query.filters,
			platform: platformFilter.value,
			time: timeFilter.value,
		}
	}

	query.limit = serversOnPage;
	query.offset = (currentPage - 1) * serversOnPage;

	let data = [];
	try {
		const fetchData = await $fetch("/api/server/many", {
			method: "POST",
			body: query,
		});

		for (const server of fetchData.servers) {
			server.poster = "/no_banner.webp"
			if (server.banner) {
				server.banner = sanitizeBanner(server.banner);
			}
			if (!server.description) {
				server.description = ""
			} else {
				server.description = server.description.replace(/<\/?[^>]+>/ig, " ");
			}
			server.href = "/server/" + titleEncode(server.title, server.custom_title_url) + "-" +
				(server.id || server._id)
		}
		data = fetchData;
	} catch (e) {
		servers.value = [];
		emptyPage = true;
		data = {
			servers: [],
			total_servers: 0,
			featured_servers_length: 0,
		};
	}

	servers.value = [];
	await new Promise(r => setTimeout(r, 1));
	servers.value = data.servers;
	if (servers.value.length === 0) emptyPage = true;

	seoServers.value = servers.value.filter((server) => {
		return props.filter !== 'default' || !server.featured;
	}).map((server) => {
		return {
			_id: server._id,
			status: {
				players: {
					online: server.status?.players?.online ?? 0,
					max: server.status?.players?.max ?? 0,
				}
			},
			ip: server.ip,
		};
	});

	totalServers.value = data.total_servers;
	featuredServersLength.value = emptyPage ? 0 : data.featured_servers_length;

	if (data.tagName) {
		tagName.value = data.tagName;
	}

	maxPages.value = Math.ceil(totalServers.value / serversOnPage);
}

await refreshData();

let error404 = false;

if (totalServers.value - ((currentPage - 1) * serversOnPage) <= 0 || totalServers.value ===
	featuredServersLength.value) {
	error404 = true;
	setResponseStatus(404);
}

watch([sortFilter, platformFilter, timeFilter], async () => {
	await refreshData();
	await refreshIPCopyData();
});

const getPageTitle = () => {
	if (props.filter === "default") {
		return [
			"Minecraft Server List",
			"Minecraft Servers",
			"Find the best Minecraft multiplayer servers here."
		];
	} else if (props.filter === "tag") {
		return [
			`Minecraft ${encodeHTML(tagName.value)} Servers`,
			`Minecraft servers tagged as <b>${encodeHTML(tagName.value)}</b> ` +
			(servers.value.length === 0 ? "" : `(${(currentPage - 1) * serversOnPage + 1}-${
				((currentPage - 1) * serversOnPage) + servers.value.length - featuredServersLength.value})`),
		];
	} else if (props.filter === "user") {
		return "Your Servers"
	} else if (props.filter === "favourite") {
		return "Favourite Minecraft Servers"
	} else if (props.filter === "search") {
		return "Minecraft Servers by " + encodeHTML(props.search.replaceAll("+", " "));
	}
};

const pageTitle = getPageTitle();

const getSponsorInfo = () => {
	if (props.filter === "tag") {
		return "This is a sponsored server. It may not offer the " + tagName.value +
			" gamemode, but it's still worth exploring!"
	} else if (props.filter === "search") {
		return "This is a sponsored server. It may not offer the " + props.search +
			" gamemode, but it's still worth exploring!"
	} else {
		return null
	}
};

const sponsorInfo = getSponsorInfo();

function addNewServer() {
	if (!loggedInCookie.value) {
		navigateTo("?redirect=/server/add");

		setPopup('LoginPopup');

		notify({
			type: "error",
			title: "Login Required",
			message: "You need to log in to add a server."
		})
	} else {
		navigateTo("/server/add");
	}
}

const firstPageLink = computed(() => {
	const currentPath = useRoute().path;
	return currentPath.replace(/\/[0-9]+$/, "");
});

onMounted(() => {
	refreshIPCopyData();
});
</script>


<style lang="scss" scoped>
.ip-address {
  font-family: 'Azeret Mono', sans-serif;
}

.online-players-cube {
  width: .5em;
  height: .5em;
  background: #D9D9D9;
  display: inline-block;
  margin-right: 0.5em;
}

.serverlist > div {
  grid-template-columns: 9% min(467px, 100%) 1fr;
}

.seo-serverlist {
  position: absolute;
  transform: translate(-10000px, -10000px);
}

.first-page {
  img {
    filter: brightness(0) saturate(100%) invert(39%) sepia(62%) saturate(4584%) hue-rotate(211deg) brightness(90%) contrast(96%);
  }

  &:hover {
    img {
      filter: none;
    }
  }
}

.server-tile {
  animation: showServerTile 0.5s ease forwards;
  opacity: 0;
  transform: translateY(20px);
}


@keyframes showServerTile {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }

}
</style>