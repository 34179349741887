import revive_payload_client_4sVQNw7RlN from "/webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/webapp/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_6wEQMY3tee from "/webapp/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_adVF2uRka6 from "/webapp/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import click_outside_oytHm5ka8v from "/webapp/plugins/click-outside.js";
import error_fix_JCNCiXnBVC from "/webapp/plugins/error-fix.js";
import fathom_client_6QHXGUB7wS from "/webapp/plugins/fathom.client.js";
import notifications_EFFJUsRga8 from "/webapp/plugins/notifications.js";
import tags_nH224rJ3T2 from "/webapp/plugins/tags.js";
import user_client_c2sXTPisXU from "/webapp/plugins/user.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_6wEQMY3tee,
  plugin_adVF2uRka6,
  click_outside_oytHm5ka8v,
  error_fix_JCNCiXnBVC,
  fathom_client_6QHXGUB7wS,
  notifications_EFFJUsRga8,
  tags_nH224rJ3T2,
  user_client_c2sXTPisXU
]