<template>
	<Navbar v-if="!error?.fatal"/>
	<div class="error">
		<div class="text">
			<h1>Error {{ statusCode }}</h1>
			<h2>{{ props.error.message }}</h2>
		</div>
		<a href="/">Go Home</a>
	</div>
</template>

<script setup lang="ts">
import type {NuxtError} from "#app";

const props = defineProps({
    error: Object as () => NuxtError
})

const statusCode = props.error.statusCode
</script>

<style scoped>
.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 0 1em;
    box-sizing: border-box;
    text-align: center;
    font-family: Arial, sans-serif;
    background: #f7fafc;
    color: #1a202c;
}

.text, a {
    margin-bottom: 1em;
    text-align: center;
    font-size: 2rem;
}

a {
    background: #38a169;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 0.75em 1.25em;
    font-size: 1.5rem;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
    margin: 0 auto;

    &:hover,
    &:focus {
        background: #2f855a;
    }
}
</style>