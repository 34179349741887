<template>
    <div v-if="tags && tags.length > 0" class="tags">
        <IndexTag v-for="(loopTag, index) in tags" :key="'tags_' + index"  :tag="loopTag"/>
    </div>
</template>

<script setup>
const route = useRoute();
const tag = route.params.tag;

const tags = shallowRef([]);

const tagsState = useState('tags');

if (tag) {
	try {
		const { data } = await useFetch("/api/tag/similar", {
			query: {
				tag: tag,
			},
			ignoreResponseError: true,
			transform: (data) => {
				for (const fetchedTag in data.tags) {
					delete data.tags[fetchedTag].similar;
				}
				return data;
			}
		});

		if (data.value && data.value.tags) {
			tags.value = data.value.tags;
		}
	} catch (e) {
	}
}

if (!tags.value || tags.value.length === 0) {
	tags.value = tagsState.value.popularTags;
}
</script>