<template>
	<div class="flex bg-black h-fit text-white-0 gap-2 p-1 px-3 rounded-xl w-fit" :class="textSizeClass">
		<img src="/icons/cube.svg" alt="Server Platform Icon" width="16" height="16">
		<span>{{ platformText }}</span>
	</div>
</template>

<script setup>
const props = defineProps({
	platform: {
		type: Number,
		required: true
	},
	ip: {
		type: String,
		required: true
	}
});


let platformText;

switch (props.platform) {
	case 1:
		platformText = "JAVA";
		break;
	case 2:
		platformText = "BEDROCK";
		break;
	case 3:
		platformText = "JAVA AND BEDROCK";
		break;
	default:
		platformText = "JAVA";
		break;
}

let textSizeClass = "text-sm";

if (props.platform >= 3 && props.ip.length >= 30) {
	textSizeClass = `text-xs`;
} else if (props.ip.length >= 40) {
	textSizeClass = `text-xs`;
}
</script>