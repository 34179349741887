import { titleEncode } from "~/utils/helpers.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
	let trimPath = to.path.endsWith("/") && to.path !== "/" ? to.path.slice(0, -1) : to.path;

	if ((trimPath.startsWith("/search/") || trimPath.startsWith("/server-tags/")) && trimPath.split("/").length > 4) {
		const page = trimPath.split("/").pop();
		if (!isValidPage(page)) {
			return navigateTo(trimPath.replace("/" + page, ""));
		}
	} else if (trimPath.startsWith("/rank")) {
		const page = trimPath.split("/").pop();
		if (!isValidPage(page)) {
			return navigateTo("/");
		}
	}

	if (trimPath.startsWith("/search/")) {
		let param = trimPath.split("/");
		if (param.length > 2) {
			param = param[2];
			if (param.length > 0) {
				let tags = await $fetch("/api/tag/search", {
					query: {
						search: param,
						exact: true,
					},
				});

				if (tags.length > 0) {

					return navigateTo("/server-tags/" + tags[0].urlname);
				}
			}
		}
	} else if (trimPath.startsWith("/index")) {
		const query = to.query;

		if (query.action === 'vote') {
			const response = await $fetch('/api/server/' + query.listingID);

			return navigateTo(`/server/${titleEncode(response.title, response.custom_title_url)}-${query.listingID}/vote`, {
				replace: true,
				redirectCode: 301
			});
		}

		return navigateTo("/");
	} else {
		const loggedIn = useCookie("loggedIn");

		if (trimPath.startsWith("/password-reset") || trimPath.startsWith("/login")) {
			if (loggedIn.value && !from.query.redirect) {
				return navigateTo("/");
			}
		} else if (
			trimPath.startsWith("/server/") &&
			(trimPath.endsWith("/edit") ||
				trimPath.endsWith("/favourite") ||
				trimPath.endsWith("/my") ||
				trimPath.endsWith("/add"))
		) {
			if (!loggedIn.value) {
				return navigateTo("/login?redirect=" + trimPath);
			}
		}
	}
})

function isValidPage(page) {
	return /[2-9]\d*?\/?/.test(page);
}