<template>
	<div class="page" :class="{
	}">
		<div class="bg-image" v-if="showBackgroundImage">
			<div class="relative w-full h-full overflow-hidden">
				<div class="bg-image-inner"/>
			</div>
		</div>
		<Navbar/>
		<NuxtLoadingIndicator color="#38a169" :height="3"/>
		<div class="page-content  pt-32 m-lg:pt-24"
		     :class="{
				'blur-xs': !!popup,
		     }">
			<div>
				<NuxtPage/>
			</div>
			<div class="footer mx-4">
				<FooterFAQ v-if="!hiddenFooter"/>
				<FooterPages v-if="!hiddenFooter"/>
				<Footer/>
			</div>
		</div>
		<Transition name="popup" v-if="popup === 'ForgotPasswordPopup'">
			<LazyPopupsForgotPasswordPopup v-if="popup === 'ForgotPasswordPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'LoginPopup'">
			<LazyPopupsLoginPopup v-if="popup === 'LoginPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'RegisterPopup'">
			<LazyPopupsRegisterPopup v-if="popup === 'RegisterPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'UserSettingsPopup'">
			<LazyPopupsUserSettingsPopup v-if="popup === 'UserSettingsPopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'VotePopup'">
			<LazyPopupsVotePopup v-if="popup === 'VotePopup'"/>
		</Transition>
		<Transition name="popup" v-else-if="popup === 'AdminBlogImagesPopup'">
			<LazyAdminBlogImagesPopup v-if="popup === 'AdminBlogImagesPopup'"/>
		</Transition>

	</div>
	<div>
		<Transition>
			<LazyGoUp v-if="showGoUp"/>
		</Transition>
	</div>
	<notifications position="bottom right" :dangerously-set-inner-html="true" animation-type="css" width="auto"/>
</template>

<script setup>
import { notify } from "@kyvg/vue3-notification";

import("~/assets/css/hint.min.css");

const { popup, setPopup } = usePopup();

const route = useRoute();

const hiddenFooter = computed(() => useRoute().path.startsWith("/server/"));

watch(
	() => route.path,
	(currentRoute) => {
		if (!import.meta.client) return;

		if (!/\/vote$/.test(currentRoute)) {
			setPopup(null);
		}

		const toastCookie = useCookie("toast");
		if (!toastCookie.value) return;

		const { type, title } = toastCookie.value;
		notify({ type, title });
		toastCookie.value = null;

		if (
			type === "error" &&
			(title.includes("login") || title.includes("logged"))
		) {
			setPopup("LoginPopup");
		}
	}
);

const meta = {
	title: "Minecraft Server List - Find Minecraft Multiplayer Servers",
	ogTitle: "Minecraft Server List - Find Minecraft Multiplayer Servers",
	keywords: "minecraft ip list, minecraft server list, minecraft servers, minecraft ip addresses, minecraft server ip addresses list, minecraft multiplayer",
	description: "Find minecraft multiplayer servers IP/address on our Minecraft server list. Search for your favourite type of multiplayer server here.",
	ogDescription: "Find minecraft multiplayer servers IP/address on our Minecraft server list. Search for your favourite type of multiplayer server here.",
	lang: "en",
};

useServerSeoMeta(meta);
useSeoMeta(meta);

useServerHead({
	link: [
		{
			rel: "canonical",
			href: "https://wwww.minecraftiplist.com" + useRoute().path,
		},
	],
	htmlAttrs: {
		lang: "en",
	},
});

const showBackgroundImage = ref(false);

function checkBackgroundImage() {
	showBackgroundImage.value = !/server\/(.*-\d+|my|.*\/edit|add)|admin|terms-and-conditions.*/g.test(route.path);
}

watch(() => route.path, () => {
	checkBackgroundImage();
});

const showGoUp = ref(false);

onMounted(() => {
	checkBackgroundImage();

	// Go Up Button
	showGoUp.value = window.scrollY > 300;
	document.addEventListener("scroll", () => {
		showGoUp.value = window.scrollY > 300;
	});
});
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
}

body {
    background: #f5f5f4;
}

html,
body,
a,
button,
p,
span {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

* {
    transition: all 0.35s ease-in-out;
}

.bg-image {
    position: absolute;
    top: -11%;
    left: 0;
    width: 100%;
    height: 55vh;
    z-index: -4;

    background: linear-gradient(180deg, #90c1fc 0%, #90c1fc 20%, white 100%);
}

.bg-image-inner {
    background-image: url('/images/clouds.png');
    background-size: contain;
    background-repeat: repeat;
    animation: moveToRight 100000s linear infinite;
    background-position: 0% 0%;
    z-index: -3;
    position: absolute;
    top: 0;
    left: 0;
    width: 2000000px;
    height: 100%;
}

@keyframes moveToRight {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 0%;
    }
}

.bg-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(245, 245, 244, 0) 40%, rgba(245, 245, 244, 1));
    background-size: cover;
    background-repeat: no-repeat;

    z-index: 0;
}

.page-content {
    width: min(1250px, 100%);
    margin: 0 auto;
    z-index: 10;

    @media (max-width: 1536px) and (min-width: 1200px) {
        width: min(1250px, 100%);
    }

    @media (max-width: 1200px) and (min-width: 1024px) {
        width: 97%;
    }
}

.v-enter-active,
.v-leave-active {
    transition: all 0.4s ease-in-out;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

video::-webkit-media-controls-start-playback-button {
    display: none;
}

.vue-notification-group {
    bottom: 1em !important;
    right: 1em !important;

    min-width: 326px;
    max-width: 600px;
}

.vue-notification-wrapper {
    overflow: auto !important;
}

.vue-notification {
    font-size: 1rem;

    min-height: 64px;
    max-height: 800px;

    display: flex !important;
    align-items: center;

    border: 0;
    border-radius: 8px;

    padding: 1em !important;

    margin: .5em 0 0 0 !important;

    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);

    justify-content: space-between;

    &::after {
        content: "x";
        font-family: monospace;
        font-size: 1.25rem;
        cursor: pointer;
    }

    .notification-title {
        display: flex;
        align-items: center;
        max-width: 90%;
    }

    .notification-title::before {
        content: "";
        filter: brightness(0) invert(1);
        display: block;

        background-repeat: no-repeat;

        margin: auto 18px auto 0px;
        outline: none;
        border: none;
        padding: 0;
        transition: 0.3s ease-in-out;
        align-items: center;
        width: 32px;
        height: 32px;
    }

    &.success {
        background: #4caf50;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjay1jaXJjbGUiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWNoZWNrLWNpcmNsZSBmYS13LTE2IFZ1ZS1Ub2FzdGlmaWNhdGlvbl9faWNvbiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNNTA0IDI1NmMwIDEzNi45NjctMTExLjAzMyAyNDgtMjQ4IDI0OFM4IDM5Mi45NjcgOCAyNTYgMTE5LjAzMyA4IDI1NiA4czI0OCAxMTEuMDMzIDI0OCAyNDh6TTIyNy4zMTQgMzg3LjMxNGwxODQtMTg0YzYuMjQ4LTYuMjQ4IDYuMjQ4LTE2LjM3OSAwLTIyLjYyN2wtMjIuNjI3LTIyLjYyN2MtNi4yNDgtNi4yNDktMTYuMzc5LTYuMjQ5LTIyLjYyOCAwTDIxNiAzMDguMTE4bC03MC4wNTktNzAuMDU5Yy02LjI0OC02LjI0OC0xNi4zNzktNi4yNDgtMjIuNjI4IDBsLTIyLjYyNyAyMi42MjdjLTYuMjQ4IDYuMjQ4LTYuMjQ4IDE2LjM3OSAwIDIyLjYyN2wxMDQgMTA0YzYuMjQ5IDYuMjQ5IDE2LjM3OSA2LjI0OSAyMi42MjguMDAxeiI+PC9wYXRoPjwvc3ZnPg==");
        }
    }

    &.warn {
        background: #ffc107;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,ICA8c3ZnCiAgICBhcmlhLWhpZGRlbj0idHJ1ZSIKICAgIGZvY3VzYWJsZT0iZmFsc2UiCiAgICByb2xlPSJpbWciCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICB2aWV3Qm94PSIwIDAgNTEyIDUxMiIKICA+CiAgICA8cGF0aAogICAgICBmaWxsPSJjdXJyZW50Q29sb3IiCiAgICAgIGQ9Ik01MDQgMjU2YzAgMTM2Ljk5Ny0xMTEuMDQzIDI0OC0yNDggMjQ4UzggMzkyLjk5NyA4IDI1NkM4IDExOS4wODMgMTE5LjA0MyA4IDI1NiA4czI0OCAxMTEuMDgzIDI0OCAyNDh6bS0yNDggNTBjLTI1LjQwNSAwLTQ2IDIwLjU5NS00NiA0NnMyMC41OTUgNDYgNDYgNDYgNDYtMjAuNTk1IDQ2LTQ2LTIwLjU5NS00Ni00Ni00NnptLTQzLjY3My0xNjUuMzQ2bDcuNDE4IDEzNmMuMzQ3IDYuMzY0IDUuNjA5IDExLjM0NiAxMS45ODIgMTEuMzQ2aDQ4LjU0NmM2LjM3MyAwIDExLjYzNS00Ljk4MiAxMS45ODItMTEuMzQ2bDcuNDE4LTEzNmMuMzc1LTYuODc0LTUuMDk4LTEyLjY1NC0xMS45ODItMTIuNjU0aC02My4zODNjLTYuODg0IDAtMTIuMzU2IDUuNzgtMTEuOTgxIDEyLjY1NHoiCiAgICA+PC9wYXRoPgogIDwvc3ZnPg==");
        }
    }

    &.info {
        background: #2196f3;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,ICA8c3ZnCiAgICBhcmlhLWhpZGRlbj0idHJ1ZSIKICAgIGZvY3VzYWJsZT0iZmFsc2UiCiAgICByb2xlPSJpbWciCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICB2aWV3Qm94PSIwIDAgNTEyIDUxMiIKICA+CiAgICA8cGF0aAogICAgICBmaWxsPSJjdXJyZW50Q29sb3IiCiAgICAgIGQ9Ik0yNTYgOEMxMTkuMDQzIDggOCAxMTkuMDgzIDggMjU2YzAgMTM2Ljk5NyAxMTEuMDQzIDI0OCAyNDggMjQ4czI0OC0xMTEuMDAzIDI0OC0yNDhDNTA0IDExOS4wODMgMzkyLjk1NyA4IDI1NiA4em0wIDExMGMyMy4xOTYgMCA0MiAxOC44MDQgNDIgNDJzLTE4LjgwNCA0Mi00MiA0Mi00Mi0xOC44MDQtNDItNDIgMTguODA0LTQyIDQyLTQyem01NiAyNTRjMCA2LjYyNy01LjM3MyAxMi0xMiAxMmgtODhjLTYuNjI3IDAtMTItNS4zNzMtMTItMTJ2LTI0YzAtNi42MjcgNS4zNzMtMTIgMTItMTJoMTJ2LTY0aC0xMmMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmg2NGM2LjYyNyAwIDEyIDUuMzczIDEyIDEydjEwMGgxMmM2LjYyNyAwIDEyIDUuMzczIDEyIDEydjI0eiIKICAgID48L3BhdGg+CiAgPC9zdmc+Cg==");
        }
    }

    &.error {
        background: #ff5252;
        color: #fff;

        .notification-title::before {
            background-image: url("data:image/svg+xml;base64,ICA8c3ZnCiAgICBhcmlhLWhpZGRlbj0idHJ1ZSIKICAgIGZvY3VzYWJsZT0iZmFsc2UiCiAgICByb2xlPSJpbWciCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICB2aWV3Qm94PSIwIDAgNTc2IDUxMiIKICA+CiAgICA8cGF0aAogICAgICBmaWxsPSJjdXJyZW50Q29sb3IiCiAgICAgIGQ9Ik01NjkuNTE3IDQ0MC4wMTNDNTg3Ljk3NSA0NzIuMDA3IDU2NC44MDYgNTEyIDUyNy45NCA1MTJINDguMDU0Yy0zNi45MzcgMC01OS45OTktNDAuMDU1LTQxLjU3Ny03MS45ODdMMjQ2LjQyMyAyMy45ODVjMTguNDY3LTMyLjAwOSA2NC43Mi0zMS45NTEgODMuMTU0IDBsMjM5Ljk0IDQxNi4wMjh6TTI4OCAzNTRjLTI1LjQwNSAwLTQ2IDIwLjU5NS00NiA0NnMyMC41OTUgNDYgNDYgNDYgNDYtMjAuNTk1IDQ2LTQ2LTIwLjU5NS00Ni00Ni00NnptLTQzLjY3My0xNjUuMzQ2bDcuNDE4IDEzNmMuMzQ3IDYuMzY0IDUuNjA5IDExLjM0NiAxMS45ODIgMTEuMzQ2aDQ4LjU0NmM2LjM3MyAwIDExLjYzNS00Ljk4MiAxMS45ODItMTEuMzQ2bDcuNDE4LTEzNmMuMzc1LTYuODc0LTUuMDk4LTEyLjY1NC0xMS45ODItMTIuNjU0aC02My4zODNjLTYuODg0IDAtMTIuMzU2IDUuNzgtMTEuOTgxIDEyLjY1NHoiCiAgICA+PC9wYXRoPgogIDwvc3ZnPgo=");

            width: 32px;
            height: 28px;
        }
    }
}

.is-correct {
    position: absolute;
    width: 16px;
    height: 16px;

    top: 50%;
    transform: translateY(-50%);
    right: 0.5em;
    transition: none;
}

.is-correct--true {
    background: url("/icons/tick.svg") no-repeat center;
    background-size: 16px;
    filter: invert(80%) sepia(46%) saturate(5893%) hue-rotate(74deg) brightness(103%) contrast(78%);
}

.is-correct--false {
    position: absolute;
    width: 16px;
    height: 16px;

    background: url("/icons/cross.svg") no-repeat center;
    background-size: 20px;
    filter: invert(25%) sepia(89%) saturate(6900%) hue-rotate(356deg) brightness(99%) contrast(118%);
}
</style>

<style scoped>
.popup-enter-active,
.popup-leave-active {
    transition: opacity 0.35s ease-in-out;
}

.popup-enter-from,
.popup-leave-to {
    opacity: 0;
}
</style>