<template>
	<button class="ip-chip  relative flex border border-black p-1 px-3 gap-2 rounded-3xl items-center !leading-6 h-fit shadow-xl
		        hover:bg-gray-200 hover:underline
				"
	        :class="{
				[props.classes]: true,
				[textSizeClass]: true,
				'hint--top': tooltip && tooltipPlacement === 'top',
				'hint--bottom': tooltip && tooltipPlacement === 'bottom',
				'hint--rounded': tooltip
	        }"
			aria-label="Copy to clipboard"
	        :copy-animation="clickEventAnimation"
	        @click="copyIPAddress">
		<img src="/icons/clipboard.svg" alt="Server IP Icon" width="13" height="16">
		<span class="relative">{{ props.ip }}</span>
	</button>
</template>

<script setup>
import { copyIP } from '~/utils/helpers.js';

const props = defineProps({
	tooltipPlacement: {
		type: String,
		default: 'bottom',
	},
	classes: {
		type: String,
		default: ''
	},
	ip: {
		type: String,
		required: true,
	},
	platform: {
		type: Number,
		default: 1
	}
});

let textSizeClass = "text-sm";

if (props.platform >= 3 && props.ip.length > 25) {
	textSizeClass = `text-xs`;
} else if (props.ip.length > 35) {
	textSizeClass = `text-xs`;
}

const tooltip = ref(true);

const clickEventAnimation = ref(false);
const clickEventAnimationTimeout = ref(null);

async function copyIPAddress() {
	const res = await copyIP(props.ip);
	if(!res) return;
	clickEventAnimation.value = true;

	tooltip.value = false;

	if (clickEventAnimationTimeout.value) {
		clearTimeout(clickEventAnimationTimeout.value);
	}
	clickEventAnimationTimeout.value = setTimeout(() => {
		clickEventAnimation.value = false;
		tooltip.value = true;
	}, 700);
}
</script>

<style>
.ip-chip span {
    font-family: 'Azeret Mono', sans-serif;
    font-weight: 300;
}

button[copy-animation="true"] {
    transform: translate(0px, 5px);
    box-shadow: none;
}

button[copy-animation="true"] span::after {
    content: "Copied!";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f5f4;
    text-align: center;
    line-height: 1.5;
    border-radius: 3px;
    z-index: 100;

    font-family: 'Azeret Mono', sans-serif;
    font-weight: 300;
    font-size: 1rem;

	transition: 0.35s ease-in-out;
}

button[copy-animation="true"]:hover span::after {
    background: #DEDEDE;
}

</style>