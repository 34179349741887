<template>
	<IndexSearchBar/>
	<IndexTags/>
	<IndexServerList filter="default"/>
</template>

<script setup>
useServerHead({
	link: [
		{
			rel: "canonical",
			href: "https://www.minecraftiplist.com",
		},
	],
});
</script>