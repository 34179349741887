
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexl0F85eAvEAMeta } from "/webapp/pages/admin/index.vue?macro=true";
import { default as logslJRjy6A6NmMeta } from "/webapp/pages/admin/logs.vue?macro=true";
import { default as serversptGwA20Q6OMeta } from "/webapp/pages/admin/servers.vue?macro=true";
import { default as usersVtqwyLRuQwMeta } from "/webapp/pages/admin/users.vue?macro=true";
import { default as contactH3yvUcSxPCMeta } from "/webapp/pages/contact.vue?macro=true";
import { default as indexxwoz2jbduuMeta } from "/webapp/pages/index.vue?macro=true";
import { default as loginZgqWSJcJqoMeta } from "/webapp/pages/login.vue?macro=true";
import { default as index8z9z04VyNgMeta } from "/webapp/pages/password-reset/[id]/index.vue?macro=true";
import { default as _91_91page_93_93VlMdNEdyrlMeta } from "/webapp/pages/rank/[[page]].vue?macro=true";
import { default as _91_91page_93_93dYnwz0TATpMeta } from "/webapp/pages/search/[search]/[[page]].vue?macro=true";
import { default as _91_91page_93_93zKJDXZgX7BMeta } from "/webapp/pages/server-tags/[tag]/[[page]].vue?macro=true";
import { default as editNSUZhNw8aCMeta } from "/webapp/pages/server/[id]/edit.vue?macro=true";
import { default as indexO1v1FauSTlMeta } from "/webapp/pages/server/[id]/index.vue?macro=true";
import { default as votenA1ZHsO7blMeta } from "/webapp/pages/server/[id]/vote.vue?macro=true";
import { default as add8F4DdAAtPVMeta } from "/webapp/pages/server/add.vue?macro=true";
import { default as _91_91page_93_93en8JOGPxEwMeta } from "/webapp/pages/server/favourite/[[page]].vue?macro=true";
import { default as _91_91page_93_93p6O0P8JnGOMeta } from "/webapp/pages/server/my/[[page]].vue?macro=true";
import { default as reuploadwwmke8eGoMMeta } from "/webapp/pages/server/reupload.vue?macro=true";
import { default as terms_45and_45conditionsViYAvIEQN7Meta } from "/webapp/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubcBSMgdGcl4Meta } from "/webapp/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcBSMgdGcl4 } from "/webapp/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/webapp/pages/admin/index.vue")
  },
  {
    name: "admin-logs",
    path: "/admin/logs",
    component: () => import("/webapp/pages/admin/logs.vue")
  },
  {
    name: "admin-servers",
    path: "/admin/servers",
    component: () => import("/webapp/pages/admin/servers.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/webapp/pages/admin/users.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/webapp/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/webapp/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/webapp/pages/login.vue")
  },
  {
    name: "password-reset-id",
    path: "/password-reset/:id()",
    component: () => import("/webapp/pages/password-reset/[id]/index.vue")
  },
  {
    name: "rank-page",
    path: "/rank/:page?",
    component: () => import("/webapp/pages/rank/[[page]].vue")
  },
  {
    name: "search-search-page",
    path: "/search/:search()/:page?",
    component: () => import("/webapp/pages/search/[search]/[[page]].vue")
  },
  {
    name: "server-tags-tag-page",
    path: "/server-tags/:tag()/:page?",
    component: () => import("/webapp/pages/server-tags/[tag]/[[page]].vue")
  },
  {
    name: "server-id-edit",
    path: "/server/:id()/edit",
    component: () => import("/webapp/pages/server/[id]/edit.vue")
  },
  {
    name: "server-id",
    path: "/server/:id()",
    component: () => import("/webapp/pages/server/[id]/index.vue")
  },
  {
    name: "server-id-vote",
    path: "/server/:id()/vote",
    component: () => import("/webapp/pages/server/[id]/vote.vue")
  },
  {
    name: "server-add",
    path: "/server/add",
    component: () => import("/webapp/pages/server/add.vue")
  },
  {
    name: "server-favourite-page",
    path: "/server/favourite/:page?",
    component: () => import("/webapp/pages/server/favourite/[[page]].vue")
  },
  {
    name: "server-my-page",
    path: "/server/my/:page?",
    component: () => import("/webapp/pages/server/my/[[page]].vue")
  },
  {
    name: "server-reupload",
    path: "/server/reupload",
    component: () => import("/webapp/pages/server/reupload.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/webapp/pages/terms-and-conditions.vue")
  },
  {
    name: component_45stubcBSMgdGcl4Meta?.name,
    path: "/mcuser:",
    component: component_45stubcBSMgdGcl4
  },
  {
    name: component_45stubcBSMgdGcl4Meta?.name,
    path: "/mcuser/:pathMatch(.*)",
    component: component_45stubcBSMgdGcl4
  },
  {
    name: component_45stubcBSMgdGcl4Meta?.name,
    path: "/nsearch",
    component: component_45stubcBSMgdGcl4
  },
  {
    name: component_45stubcBSMgdGcl4Meta?.name,
    path: "/nsearch/:pathMatch(.*)",
    component: component_45stubcBSMgdGcl4
  }
]