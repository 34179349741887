export default defineNuxtPlugin(async (nuxtApp) => {
	const tags = useState("tags");

	await callOnce(async () => {
		const tagsData = await $fetch("/api/tag", {
			query: {
				limit: 25,
			},
			ignoreResponseError: true
		});

		if (tagsData) {
			tags.value = tagsData;
		}
	});
});